import "./microsoft-button.css?__remix_sideEffect__";

import classNames from 'classnames';
import { useDarkMode } from 'usehooks-ts';

import microsoftButtonDark from '../../assets/ms-button-dark.svg';
import microsoftButtonLight from '../../assets/ms-button-light.svg';

export function MicrosoftButton() {
  const { isDarkMode } = useDarkMode({
    defaultValue: true,
    initializeWithValue: false,
    localStorageKey: 'dark-mode'
  });

  const wrapperClassName = classNames('microsoft-button__wrapper', {
    'microsoft-button__wrapper--light': !isDarkMode
  });

  return (
    <button
      type="submit"
      className="microsoft-button"
      aria-label="Sign in with Microsoft">

      <div className={wrapperClassName}>
        <img
          src={isDarkMode ? microsoftButtonDark : microsoftButtonLight}
          alt="Microsoft" />

      </div>
    </button>);

}