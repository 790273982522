import "./avatar.css?__remix_sideEffect__";

import * as RadixAvatar from '@radix-ui/react-avatar';
import classNames from 'classnames';

import anonymousAvatar from '../../assets/anonymous.png';

type Props = {
  variant?:
  'leader-small' |
  'anonymous-small' |
  'leader-large' |
  'leader-menu' |
  'anonymous-menu';
  url?: string;
};

export function Avatar({ variant = 'leader-small', url = '' }: Props) {
  return (
    <RadixAvatar.Root className={classNames('avatar', `avatar--${variant}`)}>
      <RadixAvatar.Image
        className="avatar__image"
        alt="Avatar"
        src={url || anonymousAvatar} />

    </RadixAvatar.Root>);

}