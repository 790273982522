import "./text-field.css?__remix_sideEffect__";

import classNames from 'classnames';

type Props = {
  label: string;
  name: string;
  type?: 'email' | 'password' | 'text';
  error?: boolean;
  required?: boolean;
  autoComplete?: string;
};

export function TextField({
  label,
  name,
  type = 'text',
  error = false,
  required = false,
  autoComplete = 'on'
}: Props) {
  const labelClassname = classNames('text-field__label', { error });

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control -- label is wrapped around input so it is already associated
    <label className={labelClassname}>
      <input
        name={name}
        className="text-field__input"
        type={type}
        required={required}
        autoComplete={autoComplete}
        // placeholder is needed for animation
        placeholder="" />

      <span>{label}</span>
    </label>);

}