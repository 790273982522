import "./dropdown-menu.css?__remix_sideEffect__";

import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  items: ReactNode[];
  side?: 'bottom' | 'left' | 'right' | 'top';
};

export function DropdownMenu({ children, items, side = 'bottom' }: Props) {
  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger className="dropdown-menu">
        {children}
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content
          className="dropdown-menu__content"
          side={side}
          sideOffset={5}>

          {items?.map((item, idx) =>
          <RadixDropdownMenu.DropdownMenuItem
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          className="dropdown-menu__item">

              {item}
            </RadixDropdownMenu.DropdownMenuItem>
          )}
          <RadixDropdownMenu.Arrow className="dropdown-menu__arrow" />
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>);

}