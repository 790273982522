import "./icon-button.css?__remix_sideEffect__";

import { Link } from '@remix-run/react';
import classNames from 'classnames';
import type { ReactNode } from 'react';

type CommonProps = {
  ariaLabel: string;
  color?: 'primary' | 'default';
  disabled?: boolean;
  children: ReactNode;
};

type ButtonProps = {
  type?: 'button' | 'submit';
  onClick?: () => void;
  to?: never;
};

type LinkProps = {
  to: string;
  type?: never;
  onClick?: never;
};

type Props = CommonProps & (ButtonProps | LinkProps);

export function IconButton({
  ariaLabel,
  color = 'default',
  children,
  to,
  type,
  disabled,
  onClick
}: Props) {
  const commonProps = {
    'aria-label': ariaLabel,
    className: classNames('icon-button', `icon-button--${color}`)
  };

  if (to) {
    return (
      <Link to={to} {...commonProps}>
        {children}
      </Link>);

  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} disabled={disabled} onClick={onClick} {...commonProps}>
      {children}
    </button>);

}