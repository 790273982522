import "./button.css?__remix_sideEffect__";

import classNames from 'classnames';
import type { ReactNode } from 'react';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  accent?: boolean;
  width?: string;
  height?: string;
  disabled?: boolean;
  children?: ReactNode;
};

export function Button({
  type = 'button',
  accent,
  height = '52px',
  width = '100%',
  disabled = false,
  children
}: Props) {
  const buttonClassName = classNames('button', { 'button--accent': accent });

  return (
    <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={buttonClassName}
    disabled={disabled}
    style={{ height, width }}>

      {children}
    </button>);

}