import "./tooltip.css?__remix_sideEffect__";

import * as RadixTooltip from '@radix-ui/react-tooltip';
import type { ReactNode } from 'react';

type TooltipProps = {
  title: string;
  children: ReactNode;
  side?: 'bottom' | 'left' | 'right' | 'top';
};

export function Tooltip({ title, children, side = 'bottom' }: TooltipProps) {
  return (
    <RadixTooltip.Provider delayDuration={300}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className="tooltip__content"
            side={side}
            sideOffset={5}>

            {title}
            <RadixTooltip.Arrow className="tooltip__arrow" />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>);

}